import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Helmet } from "react-helmet"
import { centerDiv } from '../components/layout.module.css'

const IndexPage = () => {
  return (
    <div className={centerDiv}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Katana Quest</title>
      </Helmet>
      
      <StaticImage alt="" src="../images/title.png" />
    </div>
  )
}

export default IndexPage